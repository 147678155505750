import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
/* eslint-disable react/prop-types */
function SignUpForm({ onSubmit, lang = 'de' }) {
  const [submitDisabled, setSubmitDisabled] = React.useState(false)

  const handleSubmit = (person, { setFieldError }) => {
    setSubmitDisabled(true)
    onSubmit(person)
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        lang === 'de'
          ? 'Bitte eine gültige E-Mail eingeben.'
          : 'Please enter a valid email.'
      )
      .required(
        lang === 'de' ? 'E-Mail ist ein Pflichtfeld' : 'Email is required. '
      ),
    firstName: Yup.string()
      .required(
        lang === 'de' ? 'Dies ist ein Pfllichtfeld' : 'Please enter a name.'
      )
      .min(
        2,
        lang === 'de'
          ? 'Bitte ganzen Vornamen eingeben.'
          : 'Please enter your name.'
      ),
    lastName: Yup.string()
      .required(
        lang === 'de' ? 'Dies ist ein Pflichtfeld ' : 'Please enter a name.'
      )
      .min(
        2,
        lang === 'de'
          ? 'Bitte ganzen Namen eingeben'
          : 'Please enter your surname.'
      ),
    gender: Yup.string().required(
      lang === 'de' ? 'Dies ist ein Pflichtfeld ' : 'Please select a gender.'
    ),
    age: Yup.string(),
    location: Yup.string().required(
      lang === 'de' ? 'Dies ist ein Pflichtfeld.' : 'Please select a location.'
    ),
    wunderlandVisits: Yup.string().required(
      lang === 'de' ? 'Dies ist ein Pfllichtfeld.' : 'This field is required.'
    ),
    insiderOptin: Yup.bool().oneOf(
      [true],
      lang === 'de' ? 'Dies ist ein Pflichtfeld' : 'x'
    ),
    newsletterOptin: Yup.bool().oneOf([true, false], lang === 'de' ? 'x' : 'x'),
  })

  return (
    <div id="signup_form">
      <Row>
        <Col>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              gender: '',
              age: '',
              location: '',
              wunderlandVisits: '',
              insiderOptin: false,
              newsletterOptin: false,
            }}
            onSubmit={(values, errors) => {
              handleSubmit(values, errors)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-4 pt-0 gy-4">
                  <Col sm={12} md={6}>
                    <Form.Group controlId="firstName">
                      <Form.Control
                        type="text"
                        placeholder="Vorname"
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        isInvalid={touched.firstName && !!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="lastName">
                      <Form.Control
                        type="text"
                        placeholder="Nachname"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        isInvalid={touched.lastName && !!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Control
                        type="mail"
                        placeholder="E-Mail"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pt-0 mb-1">
                  <Col>
                    <Form.Group controlId="gender">
                      <Form.Label className="fs-5 font-weight-bolder">
                        Geschlecht
                      </Form.Label>
                      <div>
                        {[
                          ['m', 'm'],
                          ['f', 'w'],
                          ['x', 'x'],
                        ].map(([val, lbl]) => (
                          <Form.Check
                            inline
                            key={`gender-${val}`}
                            type="radio"
                            value={val}
                            name="gender"
                            id={`gender-${val}`}
                            label={lbl}
                            checked={values.gender === val}
                            onChange={handleChange('gender')}
                            onBlur={handleBlur('gender')}
                            isInvalid={touched.gender && !!errors.gender}
                          />
                        ))}
                      </div>
                      <Form.Text
                        className="text-danger"
                        style={{
                          visibility:
                            touched.gender && errors.gender
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        &nbsp;{errors.gender}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pt-0 mb-4">
                  <Col>
                    <Form.Group controlId="age">
                      <Form.Label className="fs-5 font-weight-bolder">
                        Alter
                      </Form.Label>
                      <div>
                        {[
                          ['0-19', 'bis 20'],
                          ['21-30', '21-30'],
                          ['31-40', '31-40'],
                          ['41-50', '41-50'],
                          ['51-60', '51-60'],
                          ['ue60', 'über 60'],
                        ].map(([val, lbl]) => (
                          <Form.Check
                            inline
                            key={`age-${val}`}
                            type="radio"
                            value={val}
                            name="age"
                            id={`age-${val}`}
                            label={lbl}
                            checked={values.age === val}
                            onChange={handleChange('age')}
                            onBlur={handleBlur('age')}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-1 pt-2">
                  <Col>
                    <Form.Group controlId="location">
                      <Form.Label className="fs-5 font-weight-bolder">
                        Wohnort
                      </Form.Label>
                      <div>
                        {[
                          ['hamburg', 'Hamburg und Umgebung'],
                          ['germany', 'Deutschland'],
                          ['austria', 'Österreich'],
                          ['switzerland', 'Schweiz'],
                          ['denmark', 'Dänemark'],
                          ['netherlands', 'Niederlande'],
                          ['europe', 'anderes europäisches Land'],
                          ['not-europe', 'außerhalb Europa'],
                        ].map(([val, lbl]) => (
                          <Form.Check
                            inline
                            key={`location-${val}`}
                            type="radio"
                            value={val}
                            name="location"
                            id={`location-${val}`}
                            label={lbl}
                            checked={values.location === val}
                            onChange={handleChange('location')}
                            onBlur={handleBlur('location')}
                            isInvalid={touched.location && !!errors.location}
                          />
                        ))}
                      </div>
                      <Form.Text
                        style={{
                          visibility:
                            touched.location && !!errors.location
                              ? 'visible'
                              : 'hidden',
                        }}
                        className="text-danger"
                        type="invalid"
                      >
                        &nbsp;{errors.location}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2 pt-0">
                  <Col>
                    <Form.Group controlId="wunderlandVisits">
                      <Form.Label className="fs-5 font-weight-bolder">
                        Hast Du das Wunderland schon besucht?
                      </Form.Label>
                      <div>
                        {[
                          ['employee', 'Täglich (Mitarbeiter)'],
                          ['often', 'Mehrfach'],
                          ['yes', 'Ja'],
                          ['no', 'Nein'],
                        ].map(([val, lbl]) => (
                          <Form.Check
                            inline
                            key={`wunderlandVisits-${val}`}
                            type="radio"
                            value={val}
                            name="wunderlandVisits"
                            id={`wunderlandVisits-${val}`}
                            label={lbl}
                            checked={values.wunderlandVisits === val}
                            onChange={handleChange('wunderlandVisits')}
                            onBlur={handleBlur('wunderlandVisits')}
                            isInvalid={
                              touched.wunderlandVisits &&
                              !!errors.wunderlandVisits
                            }
                          />
                        ))}
                      </div>
                      <Form.Text
                        style={{
                          visibility:
                            touched.wunderlandVisits &&
                            !!errors.wunderlandVisits
                              ? 'visible'
                              : 'hidden',
                        }}
                        className="text-danger"
                        type="invalid"
                      >
                        &nbsp;{errors.wunderlandVisits}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-4 pt-1">
                  <Col>
                    <Form.Group controlId="insiderOptin" className="mb-3">
                      <Form.Check
                        key="insiderOptin"
                        type="checkbox"
                        name="insiderOptin"
                        id="insiderOptin"
                        label={
                          <span>
                            Ich habe die{' '}
                            <a
                              href="https://www.miniatur-wunderland.de/service/datenschutz/"
                              target="_new"
                            >
                              Datenschutzbestimmungen
                            </a>{' '}
                            zur Kenntnis genommen und willige ein, dass
                            zusätzlich im Rahmen des
                            Wunderland-Insider-Programms meine obigen Angaben
                            von der Miniatur Wunderland GmbH zu
                            Marktforschungszwecken genutzt werden dürfen und ich
                            zu diesen Zwecken auch per E-Mail kontaktiert werden
                            darf. Diese Einwilligung kann jederzeit widerrufen
                            werden.
                          </span>
                        }
                        checked={values.insiderOptin}
                        onChange={handleChange('insiderOptin')}
                        onBlur={handleBlur('insiderOptin')}
                        isInvalid={
                          touched.insiderOptin && !!errors.insiderOptin
                        }
                        feedback={errors.insiderOptin}
                      />
                    </Form.Group>
                    <Form.Group controlId="newsletterOptin">
                      <Form.Check
                        key="newsletterOptin"
                        type="checkbox"
                        name="newsletterOptin"
                        id="newsletterOptin"
                        label={
                          <span>
                            Ich möchte den E-Mail-Newsletter des Miniatur
                            Wunderlandes abonnieren.
                          </span>
                        }
                        checked={values.newsletterOptin}
                        onChange={handleChange('newsletterOptin')}
                        onBlur={handleBlur('newsletterOptin')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-5 pt-1">
                  <Col>
                    <Button type="submit" disabled={false && submitDisabled}>
                      Jetzt registrieren
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default SignUpForm
